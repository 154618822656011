export const seo = {
  url: 'collaboration',
  title: {
    en: 'Cooperation with a trustworthy transport company',
    es: 'Cooperación con una empresa de transporte de confianza',
    ro: 'Cooperare cu o companie de transport de încredere',
  },
  desc: {
    en: 'Start cooperation in the field of transport and logistics with an experienced TFL company. Specialized services, dedicated transport and customization.',
    es: 'Inicie la cooperación en el campo del transporte y la logística con una empresa TFL experimentada. Servicios especializados, transporte dedicado y personalización.',
    ro: 'Începeți cooperarea în domeniul transportului și logisticii cu o companie TFL cu experiență. Servicii specializate, transport dedicat si personalizare.',
  },
  keywords: [
    'omida',
    'logistics',
    'transport',
    'forwarding',
    'partnership',
    'cooperation',
    'collaboration',
  ],
}

export const intro = {
  title: {
    en: 'Collaboration',
    es: 'Colaboración',
    ro: 'Colaborare',
  },
  desc: {
    en: 'Start cooperation in the field of transport and logistics with an experienced TFL company. Specialized services, dedicated transport and customization.',
    es: 'Inicie la cooperación en el campo del transporte y la logística con una empresa TFL experimentada. Servicios especializados, transporte dedicado y personalización.',
    ro: 'Începeți cooperarea în domeniul transportului și logisticii cu o companie TFL cu experiență. Servicii specializate, transport dedicat si personalizare.',
  },
}

export const main = {
  title: {
    en: 'Cooperation with a trustworthy transport company',
    es: 'Cooperación con una empresa de transporte de confianza',
    ro: 'Cooperare cu o companie de transport de încredere',
  },
  texts: [
    {
      en: `<span><strong>Improve the company's operations</strong> thanks to cooperation with Omida Logistics - the top TFL brand in Europe. Start cooperation in the field of logistics and transport. We focus on experience, dedicated transport services and <strong>individual approach</strong> to the partner's requirements.</span>`,
      es: '<span> <strong> Mejorar las operaciones de la empresa </strong> gracias a la cooperación con Omida Logistics, la principal marca de TFL en Europa. Iniciar la cooperación en el campo de la logística y el transporte. Nos centramos en la experiencia, los servicios de transporte dedicados y el <strong> enfoque individual </strong> de los requisitos del socio. </span>',
      ro: '<span><strong>Îmbunătățiți operațiunile companiei</strong> datorită cooperării cu Omida Logistics - marca de top TFL din Europa. Începeți cooperarea în domeniul logisticii și transporturilor. Ne concentrăm pe experiență, servicii de transport dedicate și <strong>abordare individuală</strong> a cerințelor partenerului.</span>',
    },
    {
      en: '<span>Fill out the form and leave a contact to yourself. Our team will contact you and match an <strong>offer appropriate to your product</strong>.</span>',
      es: '<span> Complete el formulario y deje un contacto para usted. Nuestro equipo se comunicará con usted y buscará <strong>una oferta adecuada para su producto</strong>. </span>',
      ro: '<span>Completați formularul și lăsați-vă o persoană de contact. Echipa noastră vă va contacta și va potrivi o <strong>ofertă adecvată produsului dvs.</strong>.</span>',
    },
  ],
}
